import React, { useState } from "react";

import Box from "./Box";
import PropTypes from "prop-types";
import ReCard from "./ReCard";
import ReImage from "./ReImage";
import { useInterval } from "../core/hooks";

function ThreeRs({ items }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isRunning, setIsRunning] = useState(true);
  const SLIDE_DURATION = 5000;

  useInterval(
    () => {
      setActiveIndex(activeIndex + 1);
    },
    isRunning ? SLIDE_DURATION : null
  );

  return (
    <Box>
      <Box
        py={[0, 125, null, null]}
        pl={[0, "4.76vw", null, null]}
        position="relative"
      >
        <Box
          position="absolute"
          height="100%"
          width="100%"
          top={0}
          left={0}
          display={["none", "initial", null, null]}
        >
          {items.map((item, index) => {
            return (
              <ReImage
                key={item.title}
                src={item.image.url}
                title={item.image.title}
                active={activeIndex % items.length === index}
              />
            );
          })}
        </Box>
        <Box
          display="flex"
          width="100%"
          maxWidth={1338}
          ml="auto"
          flexDirection={["column", "row", null, null]}
        >
          {items.map((item, index) => {
            return (
              <Box key={item.title} width={["100%", "33.333%", null, null]}>
                <ReCard
                  active={activeIndex % items.length === index}
                  title={item.title}
                  text={item.text}
                  handleMouseEnter={() => {
                    setActiveIndex(index);
                    setIsRunning(false);
                  }}
                  handleMouseLeave={() => {
                    setIsRunning(true);
                  }}
                />
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}

export default ThreeRs;

ThreeRs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
};
