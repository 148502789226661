import { ABOUT_SLUG } from "../core/urls";
import BodyText from "./BodyText";
import Box from "./Box";
import { DARK } from "../core/constants";
import LinkButtonPrimary from "./LinkButtonPrimary";
import PropTypes from "prop-types";
import React from "react";
import RecycleIcon from "../assets/recycle.svg";
import RepurposeIcon from "../assets/repurpose.svg";
import ResaleIcon from "../assets/resale.svg";
import Text from "./Text";
import { useRouter } from "next/router";

function ReCard({ active, title, text, handleMouseEnter, handleMouseLeave }) {
  const router = useRouter();

  const getCardSection = (title) => {
    switch (title) {
      case "Resale":
        return {
          bg: "mushroom",
          bgHover: "lightBlue",
          link: `/${ABOUT_SLUG}#about-resale`,
          icon: <RecycleIcon />,
        };
      case "Repurpose":
        return {
          bg: "white",
          bgHover: "lightYellow",
          link: `/${ABOUT_SLUG}#about-repurpose`,
          icon: <RepurposeIcon />,
        };
      case "Recycle":
        return {
          bg: "mushroom",
          bgHover: "mint",
          link: `/${ABOUT_SLUG}#about-recycle`,
          icon: <ResaleIcon />,
        };
    }
  };
  const section = getCardSection(title);

  return (
    <Box
      position="relative"
      height={[400, null, 500, null]}
      width="100%"
      bg={section.bg}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box
        position="relative"
        width="100%"
        height="100%"
        zIndex={2}
        py={30}
        px={[15, null, 24, null]}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box>
          <Text uppercase fontSize={[22, 22, 32, 32]} lineHeight={1} pb={19}>
            {title}
          </Text>
          <BodyText>{text}</BodyText>
        </Box>
        <Box>
          {section.icon}
          <Box pt={30}>
            <LinkButtonPrimary theme={DARK} href={section.link}>
              Learn more
            </LinkButtonPrimary>
          </Box>
        </Box>
      </Box>
      <Box
        position="absolute"
        bg={section.bgHover}
        opacity={[1, active ? 1 : 0, null, null]}
        transition="opacity 300ms ease-in"
        height="100%"
        width="100%"
        top={0}
        left={0}
        zIndex={1}
      ></Box>
    </Box>
  );
}

export default ReCard;

ReCard.propTypes = {
  active: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  handleMouseEnter: PropTypes.func.isRequired,
  handleMouseLeave: PropTypes.func.isRequired,
};
