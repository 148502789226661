import BrandLink from "./BrandLink";
import PropTypes from "prop-types";
import React from "react";
import ReskinnedSwiper from "./ReskinnedSwiper";
import { RESKINNED_PROMO_ITEM } from "../core/constants";
function BrandSwiper({ heading, brands }) {
  const slidesPerView = 4.1;

  const brandsList = [RESKINNED_PROMO_ITEM, ...brands];

  return (
    <ReskinnedSwiper
      heading={heading}
      items={brandsList.map((brand) => {
        return <BrandLink key={brand.slug} brand={brand} />;
      })}
      slidesPerView={slidesPerView}
      extraVerticalSpace
    />
  );
}

export default BrandSwiper;

BrandSwiper.propTypes = {
  heading: PropTypes.string.isRequired,
  brands: PropTypes.array.isRequired,
};
